import { coreApi, priceTypeReducer, userApi, userReducer } from '@autone/ui';
import { configureStore, type Dispatch } from '@reduxjs/toolkit';

import { replenishmentApi } from './services/apis';

const store = configureStore({
  reducer: {
    [coreApi.reducerPath]: coreApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [replenishmentApi.reducerPath]: replenishmentApi.reducer,
    user: userReducer,
    priceType: priceTypeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(coreApi.middleware)
      .concat(userApi.middleware)
      .concat(replenishmentApi.middleware),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// TODO: this is a workaround to fix a type error following versions change
export type AppDispatch = Dispatch<any>;
